import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { scrollToPosition } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { isAnnualPayment } from 'api/businessLogic/quote';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import SecurePaymentFooterBlock from 'components/Footer/SecurePaymentFooter';
import Layout from 'components/Layout';
import PaymentPage from 'components/Payment/PaymentPage';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { navigatePreservingQuote } from 'helpers/navigation';
import { isQuoteSummaryValid } from 'helpers/quoteSummaryValidation';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { useQuote } from 'state/quote/quote';
import { CsHero, CsMeta } from 'types/contentStack';

const STEP = 7;

type PaymentProps = {
  data: {
    csPayment: {
      meta: CsMeta;
      hero_annual: CsHero;
      hero_monthly: {
        heading: string;
        subheading?: string;
      };
    };
  };
  location: Location & { state?: { detailsValid: boolean; hasAccount: boolean | null } };
};

export const query = graphql`
  query {
    csPayment {
      meta {
        meta_title
      }
      hero_annual {
        subheading
        heading
      }
      hero_monthly {
        subheading
        heading
      }
    }
  }
`;

const Payment: React.FC<PaymentProps> = ({ data: { csPayment }, location }) => {
  const quote = useQuote();

  const { meta, hero_annual, hero_monthly } = quote
    ? replaceQuotePlaceholders(csPayment, quote)
    : csPayment;

  useEffect(() => {
    if (quote) {
      if (quote.status !== 'Quote') {
        throw new Error('Returned quote does not have status "Quote"');
      } else if (!isQuoteSummaryValid(quote)) {
        navigatePreservingQuote(quoteAndBuyRoutes.quoteSummary);
      } else if (!location.state?.detailsValid) {
        navigatePreservingQuote(quoteAndBuyRoutes.checkYourDetails);
      }
    }
  }, [quote, location]);

  const paymentOption = quote?.policy.paymentOption;
  useEffect(() => {
    scrollToPosition(0);
  }, [paymentOption]);

  usePageTracking(meta.meta_title, !!quote);
  useCheckoutTracking(STEP, quote, 'Quote and Buy');

  return (
    <>
      <Layout meta={meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
        <LoadQuoteWrapper location={location}>
          {quote && (
            <>
              {isAnnualPayment(quote) ? (
                <HeroPlain
                  heading={hero_annual.heading}
                  subhead={hero_annual.subheading || undefined}
                />
              ) : (
                <HeroPlain
                  heading={hero_monthly.heading}
                  subhead={hero_monthly.subheading || undefined}
                />
              )}
              <PaymentPage
                quote={quote}
                hasAccount={location.state?.hasAccount || null}
              />
            </>
          )}
        </LoadQuoteWrapper>
      </Layout>
      <SecurePaymentFooterBlock />
    </>
  );
};

export default Payment;
